body {
    background-color: cadetblue;
}


.Main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 0.5vh;
    margin-top: -1.5vh;
}

/* .Main img {
    border-radius: 2vh;
} */

.search-bar {
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
}

.search {
    width: 30vw;
    height: 2.5vh;
    border-style: none;
    border-width: 1.5px;
    border-radius: 20px;
    text-align: start;
    font-size: 2vh;
    font-weight: 600;
}

.search::placeholder {
    color: black;
    text-align: center;
}

.search:focus {
    outline: none;
}

.travel-alternative {
    border-style: none;
    border-color: black;
    border-width: 1.5px;
    border-radius: 20px;
    width: 10vw;
    height: 2.5vh;
    background-color: rgb(10, 95, 24);
    font-weight: 800;
    padding-bottom: 1vh;
    /* paddign bottom correct?? */
}

.text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
}

.Settings {
    color: white;
    background-color: black;
    margin-right: 2vw;
    text-decoration: none;
    width: 10vw;
    height: 5vh;
    border-width: 0px;
    border-radius: 50px;
}